<template>
  <div>
    <div id="reseller-list-page">
      <h1 class="h1-title">Active/Paused Wholesalers</h1>
      <div v-if="!currentDealership.is_tender_account">
        <div class="flex-column">
          <div class="auto-margin shadow padding" style="font-size: 1.4em">
            <h1 style="text-align: center">Welcome To Tenders</h1>
            <p class="vertical-spacing-top-half">
              You don't seem to be setup with the iAppraise iTender system. Contact Us to learn more.
            </p>
          </div>
        </div>
      </div>
      <div v-if="currentDealership.is_tender_account">
        <search-bar
          class="margin-right flex-grow"
          availableFilterMode="reseller_list"
          placeholder="Search for name, phone number or email"
          :emitActions="true"
          @applyFilter="applyFilter"
        >
          <secondary-button
            icon="/assets/img/icon-pencil.svg"
            title="Activate"
            class="min-width-120 margin-left-05"
            :disabled="!(selectedResellers.length > 0)"
            v-on:click="activateReseller()"
          />
          <secondary-button
            icon="/assets/img/icon-pencil.svg"
            title="Pause"
            class="min-width-120 margin-left-05"
            :disabled="!(selectedResellers.length > 0)"
            v-on:click="pauseReseller()"
          />
        </search-bar>
        <filter-bar :customFilters="filters" @removeFilter="removeFilter" />
        <div class="flex-row">
          <table class="results-table margin-top">
            <tr class="header">
              <th>
                <input v-model="selectAllCheckbox" type="checkbox" name="" id="" />
              </th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
            </tr>
            <tr class="data-row" v-for="reseller in filterResellers()" v-bind:key="reseller.url">
              <td>
                <input type="checkbox" name="" id="" v-model="selectedResellers" :value="reseller" />
              </td>
              <td>{{ reseller.first_name }} {{ reseller.last_name }}</td>
              <td>{{ reseller.email }}</td>
              <td>{{ reseller.phone }}</td>
              <td>{{ resellerIsFavourite(reseller) ? "Active" : "Paused" }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { pauseFavourite, activateFavourite } from "../../api/Dealership.js";

import SearchBar from "../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../components/Views/v2/FilterBar.vue";
import SecondaryButton from "../../components/Buttons/v2/SecondaryButton.vue";
import _ from "lodash";

export default {
  name: "ResellerList",
  components: { SearchBar, FilterBar, SecondaryButton },
  data: function () {
    return {
      searchTerms: "",
      selectAllCheckbox: false,
      selectedResellers: [],
      filters: {}
    };
  },
  methods: {
    activateReseller: function () {
      this.selectedResellers.forEach(reseller => {
        if (!this.resellerIsFavourite(reseller)) {
          activateFavourite(reseller)
            .then(() => {
              this.activateFavouriteReseller({ reseller: reseller });
              this.selectedResellers = [];
            })
            .catch(error => {
              this.addError(error);
            });
        }
      });
    },
    pauseReseller: function () {
      this.selectedResellers.forEach(reseller => {
        if (this.resellerIsFavourite(reseller)) {
          pauseFavourite(reseller)
            .then(() => {
              this.pauseFavouriteReseller({ reseller: reseller });
              this.selectedResellers = [];
            })
            .catch(error => {
              this.addError(error);
            });
        }
      });
    },
    filterResellers: function () {
      var toReturn = this.currentDealership.favourite_resellers.concat(
        this.currentDealership.paused_favourite_resellers
      );
      if ("reseller_active_status" in this.filters) {
        if (this.filters["reseller_active_status"]) {
          toReturn = this.currentDealership.favourite_resellers;
        } else {
          toReturn = this.currentDealership.paused_favourite_resellers;
        }
      }
      if ("quick_search" in this.filters) {
        var quickSearch = this.filters["quick_search"];
        if (quickSearch != "") {
          toReturn = toReturn.filter(reseller => {
            return (
              reseller.first_name.toLowerCase().includes(quickSearch.toLowerCase()) ||
              reseller.email.toLowerCase().includes(quickSearch.toLowerCase()) ||
              reseller.phone.toLowerCase().includes(quickSearch.toLowerCase())
            );
          });
        }
      }
      return toReturn;
    },
    resellerIsFavourite: function (reseller) {
      return this.currentDealership.favourite_resellers.filter(reseller1 => reseller.url == reseller1.url).length > 0;
    },
    applyFilter: function (filter) {
      var newFilters = _.cloneDeep(this.filters);
      newFilters[filter.filter.key] = filter.filter.value;
      this.filters = newFilters;
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    ...mapActions({
      fetchCurrentDealership: "dealershipStore/fetchCurrentDealership",
      activateFavouriteReseller: "dealershipStore/activateFavouriteReseller",
      pauseFavouriteReseller: "dealershipStore/pauseFavouriteReseller",
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapState({
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  watch: {
    selectAllCheckbox: function (newVal) {
      if (newVal) {
        this.selectedResellers = this.filterResellers();
      } else {
        this.selectedResellers = [];
      }
    }
  },
  mounted: function () {
    this.fetchCurrentDealership();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#reseller-list-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}
</style>
